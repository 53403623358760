import React ,{ useState , useEffect } from "react";
import './CssScreen/KioskUser.css';
import './kiosk-components/PackagesShow.css';
import AutoSlider from "./kiosk-components/AutoSlider";
import { Layout, Spin , Button } from 'antd';
import Sidebar from "./kiosk-components/Sidebar";
import PackagesTop from "./kiosk-components/PackagesTop";
import MenuMain from "./kiosk-components/MenuMain";
import useUserData from "../../hooks/useUserData";
import "../kiosk/CssScreen/KioskMenuPackages.css";
import { PATH } from "../../routes/CustomRoutes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserOutlined } from '@ant-design/icons';
import PackagesHomePage from "./kiosk-components/PackagesHomePage";


const { Header, Sider, Content } = Layout;

const siderStyle = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#f5f5f5',
  paddingInline: 2,
};

const headerStyle = {
  height: 271,
  paddingInline: 48,
  backgroundColor: '#f5f5f5',
};

export default function KioskMenuPackages() {
  const [category, setCategory] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const { user } = useUserData()
  const { t } = useTranslation();

  React.useEffect(() => {
  },[user])
  
  const navigate = useNavigate();

  const backToMenu = () => {
    navigate(PATH.KIOSK_USER);
};

  return(
  <Spin spinning={loading} size={'large'}>
  <Layout>
    <Header style={headerStyle}>
        <PackagesHomePage />
    </Header>
    <Layout>
      <Sider width={354} style={siderStyle}> 
          <Sidebar showPackages={(cate) => setCategory(cate)} setLoading={(loading) => setLoading(loading)}/>
      </Sider>
      <Content className="backgr">
        <MenuMain category={category}/>
      </Content>
    </Layout>
      <AutoSlider/>
  </Layout>    
  <Button icon={<UserOutlined />} onClick={backToMenu} className="button-17" role="button">{t("profile")}</Button>
  {/* <Navigation /> */}
  

  </Spin>
  
);
}
