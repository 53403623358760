import React from "react";
import { Button, Image, Typography, Row, Col, Layout , Divider } from "antd";
import { useTranslation } from "react-i18next";
import THFlag from '../CssScreen/flagAll/thailand-flag.webp';
import USFlag from '../CssScreen/flagAll/american-flag.webp';
import RUFlag from '../CssScreen/flagAll/russia-flag.webp';
import { AppstoreAddOutlined, CheckCircleOutlined } from "@ant-design/icons";
import './MenuLanguageSelect.css';
import { Content, Footer } from "antd/es/layout/layout";
import { PATH } from "../../../routes/CustomRoutes";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const LanguageButton = ({ src, text, lang, buttonStyle, textStyle , startText }) => {
  const { i18n } = useTranslation();

  const handleLanguageChange = async () => {
    try {
      await i18n.changeLanguage(lang.toLowerCase());
    } finally {
    }
  };
  const navigate = useNavigate();

  const gotoLoginPage = () => {
    navigate(PATH.KIOSK_LOGIN)
  }

  return (
        <Layout className="card" onClick={() => { handleLanguageChange(); gotoLoginPage(); }}>  
          <Row className="containers">
            <Row className="icon-circle" 
                  style={{
                          backgroundImage: `url(${src})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat'
                        }}>
              </Row >
          <Content>
            <Col style={{ paddingTop: '35px' , paddingBottom:'0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Text className="title" style={{ ...textStyle }}>{text}</Text>
            </Col>
            <Col>
              <Divider className='linestart' style={{ width : "80%"}}/> 
              <Text className='linestart0' style={{ display: 'flex',   fontSize: 30 ,   width: '100%' ,  fontWeight: 'bold',  whiteSpace: 'nowrap',  overflow: 'hidden',  textOverflow: 'ellipsis' , textAlign:'center' ,display:'flex' ,justifyContent:'center' }}>{startText}</Text>
            </Col>
          </Content>
        </Row>
      </Layout>

  );
};

export default function MenuLanguageSelect(props) {
  return (
<Layout className="alltexttouse" style={{ position: 'fixed', bottom: 170, left: '50%', transform: 'translateX(-50%)', display: 'flex', alignItems: 'center', justifyContent: 'center' , width:'100%' }}>
<Text className="textuselanguage" style={{ fontSize:'45px', marginBottom: '80px' }}>PLEASE SELECT A LANGUAGE TO USE</Text>
      <Row gutter={[40, 0]}>
      <Col xs={24} sm={12} md={8}>
          <LanguageButton 
            src={USFlag} 
            text="English" 
            lang="en"
            startText="Get Start"
          /> 
        </Col>
        <Col xs={24} sm={12} md={8}>
          <LanguageButton 
            src={THFlag} 
            text="ไทย"
            lang="th"
            startText="เริ่มต้นใช้งาน"     
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <LanguageButton 
            src={RUFlag} 
            text="Россия" 
            lang="ru"
            startText="Начать"
          />
        </Col>
      </Row>
    </Layout>
  );
}
