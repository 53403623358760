import { Alert, Button, Col, Empty, Image, Layout, message, Row, Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import './CssScreen/KioskUser.css'
import {
  createFromIconfontCN,
  PhoneOutlined,
  MailOutlined,
  GiftOutlined,
  ShoppingCartOutlined,
  ArrowRightOutlined,
  StarFilled,
} from '@ant-design/icons';
import PackagesShow from '../kiosk/kiosk-components/PackagesShow'
import './kiosk-components/PackagesShow.css'
import Navigation from "./kiosk-components/Navigation";
import AutoSlider from "./kiosk-components/AutoSlider";
import { GET } from "../../frameworks/HttpClient";
import { URL_MEMBER, URL_PACKAGE } from "../../constants/urls";
import useUserData from "../../hooks/useUserData";
import * as _ from 'lodash'
import { set } from "js-cookie";
import { Swiper } from "antd-mobile";
import { Content } from "antd/es/layout/layout";
import { useLiff } from "react-liff";
import LogoutModalKiosk from "./kiosk-components/LogoutModalKiosk";
import logo from '../kiosk/CssScreen/cover.png';
import { useNavigate } from "react-router-dom";
import { PATH } from "../../routes/CustomRoutes";
import { useTranslation } from "react-i18next";
import PackagesHomePage from "./kiosk-components/PackagesHomePage";
import silde_right from "../kiosk/CssScreen/lottie/silde_right.json"
import Lottie from "lottie-react";

export default function KioskUser() {
  const { user } = useUserData();
  const [member, setMember] = React.useState(null)
  const [errorMessages, setErrorMessages] = React.useState(null)
  const [messageApi, contextHolder] = message.useMessage();
  const [packages, setPackages] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();

  const { Content } = Layout;
  const { Text } = Typography;

  const navigate = useNavigate();

  const backToMenu = () => {
    navigate(PATH.KIOSK_MENU_PACKAGES);
  };

  const LottieAnimation = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', zIndex: -1 }}>
        <Lottie animationData={silde_right} style={{ height: 200, width: 200 }} />
      </div>
    );
  };



const AnimatedComponent = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById('lottie-container');
      if (element) {
        element.style.display = 'none';
      }
    }, 5000);

    return () => clearTimeout(timer); 
  }, []);

  return (
    <div
      id="lottie-container"
      style={{
        position: 'fixed',
        bottom: '18%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '250px',
        height:'150px',
        zIndex: '10',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Lottie animationData={silde_right} />
    </div>
  );
};

  // ดึงข้อมูล Member
  const fetchMember = async (id) => {
    if (!id) {
      setErrorMessages("Member not found!");
      return;
    }
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_MEMBER.MEMBER}${id}`);
      setMember(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    }
  }

  // ดึงข้อมูล Packages ของ Member
  const fetchPackages = async (id) => {

    if (id == null) {
      setErrorMessages("Member not found!");
      return;
    }
    try {

      const url = `${URL_PACKAGE.MEMBER_PACKAGE}?member=${id}&is_available=${true}&ordering=start_date`
      const response = await GET(url)
      setPackages(response.data.results)
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.errorMessages
      })
    }
  }
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showLogoutModal = () => {
    setIsModalOpen(true);
  };

  const closeLogoutModal = () => {
    setIsModalOpen(false);
  };

  const fetchData = async (member) => {
    setLoading(true)
    try {
      await fetchMember(member)
      await fetchPackages(member)
    } catch (error) {
      setErrorMessages(error && error.errorMessages)
    } finally {
      setLoading(false)
    }
  }


  React.useEffect(() => {
    if (user) {
      fetchData(user.member)
    } else {
      setErrorMessages('Member not found!')
    }
  }, [user])

  return (
    <Spin spinning={loading} size={'large'}>
      <Layout className="User-profile">
        {errorMessages &&
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{ marginBottom: "12px", textAlign: "left" }}
          />
        }
        <div>
          <PackagesHomePage />
        </div>
        <Content className="contentalluser" style={{ width: 800 }}>
          <Row>
            <Col span={24} style={{ paddingTop: 'auto' }}>
            </Col>
          </Row>
          <Col style={{ paddingTop: '300px' }} span={24} className="name_lastname">
            <Text style={{ fontSize: '56px ' }}>{_.get(member, 'name_prefix', "-")}. {_.get(member, 'full_name', "-")}</Text>
          </Col>

          <Col style={{ paddingBottom: '40px' }} span={24} className="id_user">
            <Text style={{ fontSize: '48px', color: '#BF1E2D' }}>({_.get(member, 'code', "-")})</Text>
          </Col>

          <div className="divpoints" style={{ display: 'flex', alignItems: 'center' }}>
            <Row style={{ backgroundColor: 'white', borderRadius: '20px', height: '100px', width: '400px', paddingLeft: '30px', paddingRight: '30px', justifyContent: 'center', alignContent: 'center' }}>
              <Col span={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <GiftOutlined style={{ color: '#BF1E2D', fontSize: '48px', fontWeight: 'bold' }} />
              </Col>
              <Col span={21} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className="divpointstext" style={{ paddingLeft: '20px' }}>
                  Points
                </div>
                <div className="divpointstext" >
                  {`${_.get(member, 'reward_point', "-")}`}
                </div>
              </Col>
            </Row>
          </div>
          <Row className="packagesShowlist">
            <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
              {packages && <PackagesShow packages={packages} refresh={() => fetchPackages(user.member)} member={member} />}
            </Col>
          </Row>
        </Content>
        <div>
          <LogoutModalKiosk
            open={isModalOpen}
            onClose={closeLogoutModal}
            isLiff={false}
          />
        </div>

        <Button onClick={showLogoutModal} className="button-17">
          {t("logout")}
        </Button>
          <AnimatedComponent />
        <AutoSlider />
      </Layout>

      <Button onClick={backToMenu} className="button-18"
        role="button"
        icon={<ShoppingCartOutlined
          style={{
            fontSize: '40px'
          }} />}
      >
        {t("buy_packages")}
        <ArrowRightOutlined />
      </Button>
    </Spin>

  );
};
