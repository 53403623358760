import React, { useRef } from 'react';
import { Row, Col, Input, Button, Typography, Form } from 'antd';
import { CloseOutlined, GiftOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import { POST } from '../../../frameworks/HttpClient';
import { URL_SALE } from '../../../constants/urls';
import { useTranslation } from 'react-i18next';

const UsePointForDiscount = (props) => {
  const [pointDiscount, setPointDiscount] = React.useState([]);
  const [point, setPoint] = React.useState(null);
  const { cart, member, addDiscountList } = props;
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const pointValue = Form.useWatch('amount', form);
  const { t } = useTranslation();

  const validateDiscount = async () => {
    let data = await form.validateFields();
    data['cart'] = cart.id;
    data['type'] = 'POINT';
    setErrorMessages(null);
    try {
      const response = await POST(URL_SALE.DISCOUNT_VALIDATE, data);
      setPointDiscount([response.data]);
    } catch (error) {
      setErrorMessages(error.errorMessages);
      return null;
    }
  };

  const UpdatePoints = () => {
    setPoint(
      parseInt(_.get(member, 'reward_point', '-')) - (pointDiscount[0] ? parseInt(pointDiscount[0].amount) : 0));
  };

  React.useEffect(() => {
    if (pointDiscount) {
      UpdatePoints();
      addDiscountList(pointDiscount);
    }
  }, [pointDiscount]);

  React.useEffect(() => {
    if(cart){ 
      const maxPoints = cart.net_price;
      if (parseInt(pointValue) > maxPoints) {
        form.setFieldValue('amount', parseInt(maxPoints, 0));
      }
    }
  },[pointValue])

  return (
    <div className="input-group" style={{ display: 'flex', alignItems: 'center' }}>
      <Row style={{ textAlign: 'center', width: '100%' }}>
        <Col span={2}>
          <GiftOutlined
            style={{
              fontSize: '52px',
              color: '#BF1E2D',
              marginTop: 5
            }}
          />
        </Col>
        <Col span={8}>
          <div style={{ textAlign: 'center', marginLeft: 8 }}>
            <Row>
              <Text style={{ fontSize:'20px',display: 'flex', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', display: 'flex', justifyContent: 'left' }}>
              {t("discount_points")}
              </Text>
            </Row>
            <Row>
              <Text style={{ fontSize: '20px', color: '#BF1E2D' }}>{`${point ? point : member && member.reward_point} points`}</Text>
            </Row>
          </div>
        </Col>
        <Col className="custom-col-search" span={14} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Row>
            <Form form={form}>
              <Form.Item name={'amount'} style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Input
                  type="text"
                  placeholder="Points"
                  onPressEnter={(e) => validateDiscount()}
                  allowClear={{ clearIcon: <CloseOutlined style={{ fontSize: '20px', color: 'black', paddingRight: '10px'  }} /> }}
                  style={{
                    width: '230px',
                    height: '56px',
                    fontSize: '20px',
                    borderRadius: '6px 0px 0px 6px',
                  }}
                  className={`ant-input`}
                />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '115px', height: '56px', fontSize: '20px', borderRadius: '0 6px 6px 0' }}
              onClick={() => validateDiscount()}
            >
              Apply
            </Button>
          </Row>
        </Col>
        {errorMessages && <Text style={{ fontSize: '20px', color: '#BF1E2D' }}> {t("Error_Cannot_use_points_beyond_the_limit")} </Text>}
      </Row>
    </div>
  );
};

export default UsePointForDiscount;
