import React from 'react';
import * as _ from 'lodash';
import { Layout, Image, Typography, Button, Alert } from 'antd';
import './MenuMain.css';
import logo from '../../../assets/ahc_logo.png'
import { Content } from 'antd/es/layout/layout';
import AutoSlider from './AutoSlider';
import { PRIMARY_COLOR } from '../../../constants/color';
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import { PATH } from '../../../routes/CustomRoutes';
import { useTranslation } from 'react-i18next';
import { URL_MEMBER, URL_PACKAGE, URL_SALE } from '../../../constants/urls';
import useUserData from '../../../hooks/useUserData';
import { GET, POST } from '../../../frameworks/HttpClient';
import { PAYMENT_TYPE } from '../../../constants/string';
import VdoCard from '../CssScreen/Card01.webm';
import VdoQR from '../CssScreen/QRNew_1.webm';


export default function KioskWaitingPayment(props) {
  const navigate = useNavigate();
  const isClientSupported = _.get(window, 'JSInterface', false) ? true : false;
  const { Text } = Typography;
  const { t, i18n } = useTranslation();
  const { user } = useUserData();
  const [errorMessages, setErrorMessages] = React.useState('irgflaifhlidugf;alisdugfaliugd');
  const [packageData, setPackagesData] = React.useState(null);
  const [cart, setCart] = React.useState(null)
  const [contractData, setContractData] = React.useState(null);
  const params = useParams();
  const location = useLocation();

  const isEmailSend = false

  const fetchCart = async (memberId) => {
    if (!memberId) {
      console.log("Member not found!");
      setErrorMessages('Member not found!');
      return;
    }

    setErrorMessages(null);
    try {
      const response = await GET(`${URL_SALE.CART_MEMBER}${memberId}/`);
      setCart(response.data);
    } catch (error) {
      console.log(error.errorMessages);
      setErrorMessages(error.errorMessages);
    }
  }

  const fetchContractDetail = async (memberId) => {
    if (!memberId) {
      console.log("Member not found!");
      setErrorMessages('Member not found!');
      return;
    }

    setErrorMessages(null);
    try {
      const response = await GET(`${URL_MEMBER.MEMBER}${memberId}/membership-package-detail/`)
      setContractData(response.data)
    } catch (error) {
      console.log(error.errorMessages)
      setErrorMessages(error.errorMessages);
    }
  }

  const getPrice = () => {
    let discount = location.state.discount.reduce((prev, cur) => prev + Number(_.get(cur, 'amount', 0)), Number(0))
    return _.get(cart, 'price', 0) - discount
  }
  const handleEDCPayment = () => {
    const price = getPrice();
  
    if (!isClientSupported) {
      alert(" not supported ");
      return;
    }
  
    if (price <= 0) {
      alert(" <= 0 ?? ");
      return;
    }
  
    if (params.type === PAYMENT_TYPE.CREDIT) {
      window.JSInterface.requestCreditCardPayment(price);
    } 
    else if (params.type === PAYMENT_TYPE.TRANSFER) {
      window.JSInterface.requestThaiQRPayment(price);
    } 
    else if (params.type === PAYMENT_TYPE.ALIPAY) {
      window.JSInterface.requestAliPayPayment(price);
    } 
    else if (params.type === PAYMENT_TYPE.WECHAT) {
      window.JSInterface.requestWeChatPayment(price);
    } 
    else if (params.type === PAYMENT_TYPE.DOLFIN) {
      window.JSInterface.requestDolfinPayment(price);
    } 
    else if (params.type === PAYMENT_TYPE.QR_OTHER) {
      window.JSInterface.requestQROtherPayment(price);
    } 
    else {
      alert("Unsupported payment type.");
    }
  };
  

  const handleSubmit = async (contractData) => {
    setErrorMessages(null);
    try {
      const data = {
        type: 'package',
        member: user.member,
        price: contractData.price,
        duration: contractData.duration,
        signature: null,
      }
      console.log(`Posting with data
        member: ${user.member}
        price : ${contractData.price}
        duration : ${contractData.duration}
        signature : null
      `)
      await POST(URL_MEMBER.MEMBERSHIP_PACKAGE_CONTRACT, data)
      await handlePaid(window.cart, window.paymentData)
    } catch (error) {
      console.log("Error")
      console.log(error.errorMessages)
      setErrorMessages(error.errorMessages);
    } finally {
    }

  }

  const handlePaid = async (cart, paymentData) => {
    if (!cart.id) {
      console.log("Cart not found!");
      setErrorMessages('Cart not found!');
      return;
    }
    setErrorMessages(null);
    // Create payment
    try {
      console.log("Payment Data")
      console.log(JSON.stringify(paymentData))
      await POST(`${URL_SALE.CART}${cart.id}/approve-payment/`, paymentData);
      navigate(PATH.KIOSK_PAYMENT_SUCCESS)
    } catch (error) {
      console.log("Payment error...................")
      console.log(error.errorMessages);
    }
  }

  const handleCancelPayment = async () => {
    await window.JSInterface.requestCancelPayment();
    navigate(-1)
  }


  React.useEffect(() => {
    fetchCart(user.member)
    fetchContractDetail(user.member)
  }, [user])

  React.useEffect(() => {
    if (cart != null && contractData != null) {
      window.contractData = contractData
      window.cart = cart
      window.paymentData = {
        approved_by: null,
        payments:
          [{
            title: params.type,
            amount: location.state.amount,
            type: params.type
          }],
        discounts: location.state.discount,
        send_email: isEmailSend ? isEmailSend : false,
      }
      handleEDCPayment()
    }
  }, [cart, packageData])

  React.useEffect(() => {
    window.onPaymentSuccess = async (approveCode, transDate, transtime, invoice, terminal, account, batch, merchant, cardType) => {
      console.log(`Payment success with params : 
        approveCode : ${approveCode}
        transDate : ${transDate}
        transtime : ${transtime}
        invoice : ${invoice}
        terminal : ${terminal}
        account : ${account}
        batch : ${batch}
        merchant : ${merchant}
        cardType : ${cardType}
      `)
      await handleSubmit(window.contractData)
    }

    window.onPaymentFailed = async () => {
      console.log("Payment failed.....")
    }

    return () => {
      window.onPaymentSuccess = () => null
      window.onPaymentFailed = () => null
      window.contractData = null
      window.cart = null
      window.paymentData = null
    }
  }, [])

  const getBackgroundVideo = () => {
    if (params.type === PAYMENT_TYPE.CREDIT) {
      return VdoCard;
    } else if (
      params.type === PAYMENT_TYPE.TRANSFER ||
      params.type === PAYMENT_TYPE.ALIPAY ||
      params.type === PAYMENT_TYPE.DOLFIN ||
      params.type === PAYMENT_TYPE.WECHAT
    ) {
      return VdoQR;
    } else {
      return null;
    }
  };


  const styles = {
    videoContainer: {
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      height: '100vh',
    },
    video: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: 0,
    },
    content: {
      position: 'relative',
      zIndex: 1,
    },
    button: {
      width: 400,
      height: 85,
      top: 12,
      aling: 'center',
      fontSize: 35,
      fontWeight: 450,

    }
  };

  return (
    <Layout style={styles.videoContainer} >
      {getBackgroundVideo() && (
        <video autoPlay loop muted style={styles.video} onClick={() => handleSubmit(window.contractData)} > {/* เปลียนระบบจ่าย ตังค์กดเอง  */}
          <source src={getBackgroundVideo()} type="video/webm" />
        </video>
        //   <video autoPlay loop muted style={styles.video} >
        //   <source src={getBackgroundVideo()} type="video/webm" />
        // </video>
      )}
      <Content>
        <Image src={logo} style={{ marginTop: '90px', width: '180px' }} preview={false} />
        <div className='custom-text-shadow' style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', gap: 15, paddingTop: '40px' }}>
          <Text level={1} style={{ color: PRIMARY_COLOR, fontSize: 40 }} strong> {t("kiosk_waiting.please")}</Text>
          <Text level={1} style={{ fontSize: 40 }} strong> {t("kiosk_waiting.pay_at_the")}</Text>
          <Text level={1} style={{ color: PRIMARY_COLOR, fontSize: 40 }} strong> {t("kiosk_waiting.edc")}</Text>
          <Text level={1} style={{ fontSize: 40 }} strong> {t("kiosk_waiting.machine")}</Text>
        </div>
        {errorMessages && <Alert type='error' showIcon message={t('error')} />}
        <div className='custom-text-shadow' style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', gap: 15, marginTop: 100, paddingTop: '950px' }}>
          <Button
            className='buttomnook'
            style={styles.button}
            onClick={handleCancelPayment}
          >{t("cancel")}</Button>

        </div>
      </Content>
      <AutoSlider />
    </Layout>
  )
}
