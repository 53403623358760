import React from 'react';
import { Layout, Card, Col, Row, List, Typography } from 'antd';
import './MenuMain.css';
import CardMenu from './CardMenu';
import { URL_PACKAGE } from '../../../constants/urls';
import { GET } from '../../../frameworks/HttpClient';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';


const { Header, Content } = Layout;

function getColor(index, distance) {
  return index % distance === 0 ?  '#3E3935' : '#B61F2D';
}

const headerStyle = {
  textAlign: 'center',
  height: 150,
  paddingInline: 48,
  lineHeight: '64px',
  display: 'flex',
  // background: 'none',
  
};

const contentStyle = {
  width: '75%',
  height: '95%',
};

const containerStyle = {
  overflow: 'auto',
  justify: 'center',
};

export default function MenuMain(props) {
  const { category } = props;

  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [mainPackages, setMainPackages] = React.useState([]);
  const [allClubPackages, setAllClubPackages] = React.useState([]);
  const [packagesData, setPackagesData] = React.useState([]);
  const { i18n } = useTranslation();

  const location = useLocation();
  const { name } = location.state || {};


  const fetchData = async () => {
    await fetchPackages();
    await fetchPackagesAllClub();
  };

  const fetchPackages = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_PACKAGE.PACKAGES}?page_size=100&kiosk=true`);
      setMainPackages(response.data.results);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  };

  const fetchPackagesAllClub = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_PACKAGE.ALL_CLUB_PACKAGE}?page_size=100&kiosk=true`);
      setAllClubPackages(response.data.results);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  };

  const filterPackagesRender = async (cate) => {
    if (mainPackages.length > 0 || allClubPackages.length > 0) {
      setPackagesData([
        ...(mainPackages.filter(p => p.categories.includes(`${cate}`))),
        ...(allClubPackages.filter(p => p.categories.includes(`${cate}`))),
      ]);
    }
  };


  React.useEffect(() => {
    fetchData();
  }, []);


  function getCurrentLanguageDetail(i18n) {
    const languageMapping = {
      'en': 'detail',
      'ru': 'detail_ru',
      'th': 'detail_th',
    };
    return languageMapping[i18n.language] || '-';
  }

 
  
  React.useEffect(() => {
    if (category && (mainPackages.length > 0 || allClubPackages.length > 0)) {
      filterPackagesRender(category.id);
    }
  }, [category, mainPackages, allClubPackages]);

  const text = _.get(category, 'name', '-'); // TEXT ใส่ตรงนี้ <----
  const words = text.split(' '); // แยกคำตามช่องว่าง
  const distance = 2; // ระยะห่างที่กำหนด

  return (
    <Layout>
      <Header style={headerStyle} className='backgheader'>

        <Row gutter={[0, 0]} style={{ width : '700px', fontWeight : 'bolder', textShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)", alignItems: 'center',  justifyContent: 'center', display: 'flex'}}>
          {words.map((word, index) => (
            <span key={index} style={{ color: getColor(index, distance) , textAlign: 'center', fontSize : 35, marginLeft : 10}}>
                {word}
            </span>
          ))}
          {name}
        </Row>
      </Header>
      <Layout className='contentStyle'>
        <div style={containerStyle}>
          <Content style={{ height: 1240, justifyContent: 'center', display: 'flex' }}>
            <Row style={contentStyle} gutter={[16, 16]} justify={"center"}>
              <List
                style={{ marginBottom: 200 }}
                grid={{
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                }}
                loading={loading}
                dataSource={packagesData}
                renderItem={(item, idx) => (
                  <List.Item style={{ marginBottom: idx === (packagesData.length - 1) ? 150 : '' }}>
                    <center>
                      <Col>
                        <CardMenu data={item} languageAdd={getCurrentLanguageDetail(i18n)}  />
                      </Col>
                    </center>
                  </List.Item>
                )}
              />
            </Row>
          </Content>
        </div>
      </Layout>
    </Layout>
  );
}
