import React from "react";
import { Layout, message, Spin } from "antd";
import "./CssScreen/KioskLogin.css"
import vdo from "./CssScreen/kiosk06.webm"
import { PATH } from "../../routes/CustomRoutes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useUserData from "../../hooks/useUserData";
import * as _ from "lodash";
import { URL_AUTH } from "../../constants/urls";
import { POST } from "../../frameworks/HttpClient";
import Cookies from "js-cookie";
import { COOKIE_AUTH } from "../../constants/cookies";
import LoginModalKiosk from "./kiosk-components/LoginModalKiosk";


export default function KioskLoginScreen() {
  const BARCODE_READ_TIMEOUT = 25000  // ms

  // hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUserData();
  const [messageApi, contextHolder] = message.useMessage();

  // states
  const [isLoading, setIsLoading] = React.useState(false);

  const setUserDetail = async (response) => {
    // enter user token and data
    await Cookies.set(COOKIE_AUTH.TOKEN, _.get(response, "data.token", ""), { expires: COOKIE_AUTH.TOKEN_EXPIRE_DAYS })
    await user.setUserData(_.get(response, "data.user", {}));
  }

  const navigateNext = (response) => {
    const isUserMember = _.get(response, "data.user.member", false)
    // check if user login is member or not
    if (isUserMember) {
      navigate(`${PATH.KIOSK_USER}`, { replace: true });
    } else {
      messageApi.error(t("following_QRCode_not_for_a_valid_member"))
    }
  }

  // Member Login
  const handleMemberLogin = async (id) => {
    setIsLoading(true);
    try {
      const response = await POST(URL_AUTH.MEMBER_TOKEN_AUTH, { id });
      await setUserDetail(response)
      navigateNext(response)
    } catch (error) {
      console.log(JSON.stringify(error))
      messageApi.error(t("login_failed"))
      navigate(-1)
    } finally {
      setIsLoading(false);
    }
  }

  const startReadBarcode = () => {
    if (window.JSInterface) {
      window.JSInterface.requestReadBarcode(BARCODE_READ_TIMEOUT);
    }
  }

  React.useEffect(() => {
    window.onQRCodeScan = async (result) => {
      if(!isLoading) {
        handleMemberLogin(result)
      }
    }

    startReadBarcode()
    const timeoutId = setTimeout(() => { // No barcode scanned go back
      if(!isLoading) {
        navigate(-1)
      }
    }, BARCODE_READ_TIMEOUT)

    return () => {
      window.onQRCodeScan = () => null
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <Layout className="Login">
      <Spin style={{ top: "5rem" }} size="large" spinning={isLoading} tip="Loading...">
        {contextHolder}
        <div className="Video-background">
          <video className="videoTag" autoPlay loop muted>
            <source src={vdo} type="video/webm" />
          </video>
          <div className="content" >
            <h1 className="text-please"><span style={{ color: "#bf1e2d" }}>{t("kiosk_login.please")}</span> {t("kiosk_login.scan")}</h1>
            <h1 className="text-qr">{t("kiosk_login.the")} <span style={{ color: "#bf1e2d " }}>{t("kiosk_login.QR")}</span>  {t("kiosk_login.code_to_login")}</h1>
          </div>
        </div>
        <LoginModalKiosk />
      </Spin>
    </Layout>
  )
}
