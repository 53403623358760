import React, { useState } from 'react'
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined, LoginOutlined } from '@ant-design/icons';
import { Button, Form, Input, Image, Spin, Alert, Tabs } from 'antd';

import logo from "../../../assets/ahc_logo.png";
import { POST } from "../../../frameworks/HttpClient";
import { PATH } from "../../../routes/CustomRoutes";
import { URL_AUTH } from '../../../constants/urls';
import { COOKIE_AUTH } from '../../../constants/cookies';
import Segment from '../../../components/common/Segment';
import useUserData from '../../../hooks/useUserData';
import QRCodeReader from '../../../components/common/QRCodeReader';
import "./LoginModalKiosk.css"

export default function LoginModalKiosk() {
  const navigate = useNavigate();
	const user = useUserData();
  const qrCodeReaderRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  // Member Login
  const handleMemberLogin = async (memberId) => {
    setErrorMessages(null);
    setIsLoading(true);
    let response = null;
    try {
      response = await POST(URL_AUTH.MEMBER_TOKEN_AUTH, {id: memberId});
    } catch (error) {
      setErrorMessages("Login failed!");
    } finally {
      setIsLoading(false);
    }

    return response;
  }

  // Staff Login
  const handleStaffLogin = async (values) => {
    setErrorMessages(null);
    setIsLoading(true);
    let response = null;
    try {
      response = await POST(URL_AUTH.API_TOKEN_AUTH, {...values})
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }

    if (response)
      onFinish(response.data);
  }

  const onFinish = async (data) => {
    if (!data) return;
    
    // Set data into cookies
    Cookies.set(COOKIE_AUTH.TOKEN, data.token, { expires: COOKIE_AUTH.TOKEN_EXPIRE_DAYS })
    const responseUser = data.user
    user.setUserData(responseUser);

    // Goto target page after loged-in
    if (responseUser) {
      if (!responseUser.member) {
        // For: staff login
        navigate(PATH.MAIN, {replace: true});
      } else {
        // For: member login
        navigate(`${PATH.KIOSK_USER}`, { replace: true });
        // navigate(PATH.COMING_SOON, {replace: true});
      }
    }
  };

  const renderStaffLogin = () => {
    return (
      <Spin spinning={isLoading}>
        {errorMessages && 
          <Alert
            style={{marginBottom: "12px", textAlign: "left"}}
            message={errorMessages}
            type="error"
            showIcon 
          />}
        <Form
          style={{marginTop: 8}}
          name="normal_login"
          className="login-form"
          onFinish={handleStaffLogin}
          autoComplete={"off"}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please enter your username!' }]}>
            <Input 
              autoCapitalize='none'
              prefix={<UserOutlined className="site-form-item-icon" />} 
              placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your password!' }]}>
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"/>
          </Form.Item>

          <Form.Item style={{margin: 0}}>
            <Button 
              style={{width: "100%"}}
              type={"primary"} 
              htmlType="submit" 
              shape="round" 
              icon={<LoginOutlined />}>{"Login"}</Button>
          </Form.Item>
        </Form>
      </Spin>
    )
  }
  const renderMemberLogin = () => {
    return (
      <Spin spinning={isLoading}>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: "12px", textAlign: "left"}}
          />}
        <QRCodeReader 
          ref={qrCodeReaderRef}
          qrCodeSuccessCallback={async (decodedText, result) =>  {
            try {
              const response = await handleMemberLogin(decodedText);
              if (response) {
                await qrCodeReaderRef.current.cleanup();
                onFinish(response.data);
              } 
            } catch (error) {}
          }}/>
      </Spin>
    )
  }

  const menus = [
    { key: "staff", label: "Staff", children: <div>{renderStaffLogin()}</div> },
    { key: "member", label: "Member", children: <div>{renderMemberLogin()}</div> }
  ]

  return(
    <div className='center-screen' >
      <div style={{width: "90%", maxWidth: 420}}>
        <Image 
          style={{textAlign: "center", width: "100%", maxWidth: 120}}
          src={logo} 
          preview={false} />
        
        <Segment 
          style={{marginTop: 24, padding: 24}}
          detail={<Tabs type={"card"} items={menus}/>} />
      </div>
    </div>
  )
}