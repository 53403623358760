import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { COOKIE_AUTH } from '../../../constants/cookies';
import { PATH } from '../../../routes/CustomRoutes';
import { URL_AUTH } from '../../../constants/urls';
import { POST } from '../../../frameworks/HttpClient';
import useUserData from '../../../hooks/useUserData';
import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const LogoutModalKiosk = ({ open, onClose, isLiff }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const user = useUserData();
  const { t } = useTranslation();

  const handleLogout = async () => {
    setLoading(true);
    setError(null);
    try {
      await POST(URL_AUTH.SIGN_OUT, { is_liff: isLiff });
    } catch (error) {
      console.error("Logout failed:", error);
      setError(t("logout_failed_Please_try_again."));
      return;
    } finally {
      setLoading(false);
      Cookies.remove(COOKIE_AUTH.TOKEN);
      user.removeUserData();
      const page = isLiff ? PATH.LIFF_LANDING : PATH.KIOSK;
      navigate(page);
      onClose();
    }
  };

  useIdleTimer({
    // timeout: 1000 * 60 * 15, // 15 minutes
    timeout: 1000 * 60 * 5, // 1 minutes

    onIdle: handleLogout,
    debounce: 500,
  });

  return (
    <Modal
      title={
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LogoutOutlined style={{ marginRight: 8, fontSize: '40px', color: '#BF1E2D' }} />
          <Text style={{ fontSize: '40px', color: '#BF1E2D' }}>{t("logout")}</Text>
        </span>
      }
      okText="Logout"
      cancelText="Cancel"
      centered
      open={open}
      onOk={handleLogout}
      onCancel={onClose}
      width={700}
      bodyStyle={{ textAlign: 'center', padding: '24px' }}
      footer={
        <div style={{ textAlign: 'center', padding: '10px' }}>
          {error && <Text type="danger">{error}</Text>}
          <Button onClick={onClose} style={{ marginRight: 16, fontSize: '28px', width: 'auto', height: 'auto' }}>
            {t("cancel")}
          </Button>
          <Button loading={loading} type="primary" onClick={handleLogout} style={{ fontSize: '28px', width: 'auto', height: 'auto' }} disabled={loading}>
            {t("logout")}
          </Button>
        </div>
      }
    >
      <Text style={{ fontSize: '28px' }}>{t("are_you_sure_you_want_to_logout")}</Text>
    </Modal>
  );
};

LogoutModalKiosk.defaultProps = {
  open: false,
  onClose: () => { },
  isLiff: false,
};

LogoutModalKiosk.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isLiff: PropTypes.bool,
};

export default LogoutModalKiosk;
