import React from "react";
import { Button, Card, Layout, Select, Typography, Image, Row } from "antd";
import { PATH } from "../../routes/CustomRoutes";
import { useNavigate } from "react-router-dom";
import './CssScreen/KioskVDO.css'
import vdo from './CssScreen/vdo_alpha.mp4';
import MenuLanguageSelect from "./kiosk-components/MenuLanguageSelect";
import { useTranslation } from "react-i18next";

export default function KioskVideoScreen() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const gotoLoginPage = () => {
    navigate(PATH.KIOSK_LOGIN)
  }

  const { Header, Footer, Sider, Content } = Layout;
  const { Text } = Typography;

  const setDefaultLng = async () => {
    try {
      await i18n.changeLanguage('en');
    } finally {

    }
  }

  React.useEffect(() => {
    setDefaultLng();
  },[])

  return (
    <Layout className="video-cover">
      <div className="video-background">
        <video className='videoTag' autoPlay loop muted>
          <source src={vdo} type='video/mp4' />
        </video>
    </div>
          <Row className="content">
              <MenuLanguageSelect/>
          </Row>
    </Layout>
  )
}
