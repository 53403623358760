import './PackagesShow.css';
import styles from './PackagesShow.module.css';
import React from "react";
import { Button, Card, Carousel, Col, Divider, Image, Modal, Row, Space, theme, Typography } from 'antd';
import { GET, POST } from '../../../frameworks/HttpClient';
import { Swiper, PageIndicator } from 'antd-mobile';
import * as _ from 'lodash'
import { URL_MEMBER, URL_PACKAGE } from '../../../constants/urls';
import logo from "../../../assets/ahc_logo.png";
import { CONTRACT_TYPE } from '../../../constants/string';
import MemberConsentQRCode from '../../../components/member/MemberConsentQRCode';
import { CloseCircleFilled, CheckCircleFilled, LeftOutlined, RightOutlined, MinusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const contentStyle = {
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
  width: '820px',
  height: '380px',
  margin: 'auto',
};

const buttonStyle = {
  width: '30%',
  height: 70,
  fontSize: 25,
  fontWeight: 500,
}



export default function PackagesShow(props) {
  const [packages, setPackages] = React.useState(props.packages)
  const [packageItems, setPackageItems] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [consentRequired, setConsentRequired] = React.useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false)
  const qrRef = React.useRef();
  const { Text } = Typography;
  const isFreeTrial = _.get(selected, 'is_free_trial', false);
  const [activatedPackage, setActivatedPackage] = React.useState(null)
  const { t } = useTranslation();




const PackageCard = ({ data, onClick }) => {
  const [selected, setSelected] = React.useState(null)

  return (
    <>
      <Card
        style={{ textAlign: "center" }}
        onClick={onClick}>
        <Image
          style={{ height: '180px', width: "auto" }}
          preview={false}
          src={data.is_corporate ? data.card_type_image : logo} />

        <div
          style={{
            marginTop: 16,
            fontWeight: 'bold',
            lineHeight: "1.5em",
            height: "3em",
            overflow: "hidden",
          }}>
        </div>
        <Typography.Text
          className="Usage-Remain"
          style={{
            color: 'black',
          }}
        >

          {data && (
            <>
              {data.has_dynamic && `[ALL CLUB - ${data.dynamic_package.name}] `}
              {data.package_name ? data.package_name : "Unknown"}
              {data.variant_type && ` (${data.variant_type})`}
            </>
          )}
          <div className='divlist'>
            <center>
            <Button className='button-activate' style={buttonStyle} onClick={() => onClick()}>{t("activate")}</Button>
            </center>
          </div>
        </Typography.Text>
      </Card>
    </>
  )
}

  const {
    token: { colorSuccess },
  } = theme.useToken();


  const packageRender = (packages) => {
    const mainPkg = packages.filter(pkg => pkg.is_activated).sort((p1, p2) => p1.package_name - p2.package_name)
    const allPackages = packages
    const myPackages = []
    // Main Package
    if (mainPkg != null) {
      if (mainPkg.length > 0) {
        mainPkg.map(pkg => {
          myPackages.push(
            <Swiper.Item key={pkg.id}>
              <div className='Pack-text-Box' style={{ alignItems: 'center', justifyContent: 'center', fontSize: 38 }}>
                <h3 style={{ margin: 20, lineHeight: '1.05' }}>{_.get(pkg, 'package_name', '-')} - ({_.get(pkg, 'variant_type', '-')}) <CheckCircleFilled style={{ color: colorSuccess, marginLeft: 6, fontSize: 35 }} /></h3>
                <center>
                  <div style={{ width: "90%" }}>
                    <Divider className='line' style={{ width: "80%" }} />
                  </div>
                </center>
                <Row style={{ marginTop: 16 }}>
                  <Col span={12}>
                    <Text className='START' style={{ float: "right" }}>START :</Text>
                  </Col>
                  <Col span={12}>
                    <Text className='DATE-START'>{_.get(pkg, 'start_date', '-')}</Text>
                  </Col>
                </Row>
                <Row style={{ marginTop: 16 }}>
                  <Col span={12}>
                    <Text className='EXP' style={{ float: "right" }}>EXP :</Text>
                  </Col>
                  <Col span={12}>
                    <Text className='DATE-END'>{_.get(pkg, 'end_date', '-')}</Text>
                  </Col>
                </Row>
                <Row style={{ marginTop: 16 }}>
                  <Col span={12}>
                    <Text className='Duration' style={{ float: "right" }}>Duration :</Text>
                  </Col>
                  <Col span={12}>
                    <Text className='DAY'>{_.get(pkg, 'duration_remain', '-')} DAYS</Text>
                  </Col>
                </Row>
                <Row style={{ marginTop: 16 }}>
                  <Col span={12}>
                    <Text className='Usage-Remain' style={{ float: "right" }}>Usage Remain :</Text>
                  </Col>
                  <Col span={12}>
                    <Text className='USAGE'>{pkg.usage_remain ? pkg.usage_remain : 'Unlimited'}</Text>
                  </Col>
                </Row>
              </div>

            </Swiper.Item>
          )
        })
      }
    }

    // Package not activated
    if (allPackages && mainPkg.length == 0) {
      allPackages.filter(pkg => pkg.is_activated === false && pkg.allow_duplicate_activate === false).map(item => {
        myPackages.push(
          <Swiper.Item key={item.id}>
            <PackageCard data={item} onClick={() => setSelected(item)} />
          </Swiper.Item>
        )
      })
    }

    // Package allow duplicate activate
    if (allPackages) {
      allPackages.filter(pkg => pkg.allow_duplicate_activate === true && pkg.is_activated === false).map(item => {
        myPackages.push(
          <Swiper.Item key={item.id}>
            <PackageCard data={item} onClick={() => setSelected(item)} />
          </Swiper.Item>
        )
      })
    }

    setPackageItems(myPackages);
  }

  const startActivate = async () => {
    setSelected(null)


    // Handle corporate
    if (selected.is_corporate) {
      props.onSelected(selected);
      return;
    }
    // Handle free trail
    if (selected.is_free_trial) {
      let selectedData = selected
      if (qrRef.current) {
        selectedData['send_email'] = qrRef.current.getSendEmail();
      }
      props.onSelected(selectedData);
      return;
    }

    // Handle Main Package
    setLoading(true);
    try {
      const response = await POST(`${URL_PACKAGE.MEMBER_PACKAGE}${selected.id}/activate/`);
      props.refresh()
    } catch (error) {
      console.log(error.errorMessages);
    } finally {
      setLoading(false)
    }
  }

  const checkConsentRequired = async () => {
    setLoading(true);
    try {
      if (isFreeTrial) {
        const response = await GET(
          `${URL_MEMBER.MEMBER}${_.get(props, 'member.id', '')}/check-membership-consent/`,
          { consent_type: CONTRACT_TYPE.FREE_TRIAL }
        )
        setConsentRequired(_.get(response, 'data.is_required', false));
      } else {
        setConsentRequired(false)
      }
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (packages) {
      packageRender(packages);
    }
  }, [packages])

  React.useEffect(() => {
    checkConsentRequired()
    setIsConfirmOpen(selected != null)
  }, [selected])

  React.useEffect(() => {
    if (props.member && props.packages) {
      let otherPackage = [];
      // Case: Corporate
      let corp = (props.member && props.member.corporates) ? props.member.corporates : [];
      if (corp.length > 0) {
        otherPackage = corp.map(item => (
          {
            ...item,
            is_corporate: true,
            package_name: item.card_type_name,
            end_date: null,
            allow_gym_access: true,
            allow_pool_access: true
          }
        ))
      }
      // Case: Free trial
      const isFreeTrail = props.member.allow_trial_usage;
      if (isFreeTrail) {
        otherPackage.push({
          id: props.member.id,
          is_free_trial: true,
          package_name: "Free Trail",
          end_date: null,
          allow_gym_access: true,
          allow_pool_access: true
        })
      }

      setPackages((props.packages ? props.packages : []).concat(otherPackage))
    }
  }, [props.packages, props.member]);

  return (
    <div>

      <Modal
        title={<Text className='kiosk-purchase-title' style={{ marginTop: 50, justifyContent: 'center', color: "rgb(182, 31, 45)", marginBottom: 40 }}>{t("Confirm_Activate_Package")}</Text>}
        open={isConfirmOpen}
        onCancel={() => setSelected(null)}
        width={800}
        centered
        closeIcon={<CloseCircleFilled className='custom-close-icon-modal' style={{ fontSize: 40, paddingRight: 5 }} />}
        footer={
          <Row justify='end' paddingTop='15px'>
            <Col>
              <Button
                key="back"
                style={{ fontSize: '25px' }}
                className='buttomOKandNO'
                onClick={() => setSelected(null)}
              >
                Cancel
              </Button>
            </Col>
            <Col span={1}>
            </Col>
            <Col>
              <Button
                className='buttomnook'
                style={{ fontSize: '25px' }}
                key="submit"
                type="primary"
                loading={loading}
                disabled={consentRequired ? true : false}
                onClick={startActivate}
              >
                OK
              </Button>
            </Col>
          </Row>
        }
      >

        <Row>
          <Col span={6}>
            <center>
              <Image style={{ height: '100px' }} src={logo}></Image>
            </center>
          </Col>
          <Col span={18}>
            <Row>
              <Text style={{ fontSize: 25, fontWeight: 700, paddingTop: '10px' }}>
                {t("Are_you_sure_to_activate_package")}
              </Text>
            </Row>
            <Row>
              <Text style={{ fontSize: '24px', fontWeight: 400, paddingTop: '13px', textAlign: 'center' }}>
                {selected &&
                  <>
                    {selected.has_dynamic && `[ALL CLUB - ${selected.dynamic_package.name} ] `}
                    {selected.package_name ? selected.package_name : "Unknown"}
                    {selected.variant_type && ` (${selected.variant_type})`}
                  </>
                }
              </Text>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <MemberConsentQRCode
              ref={qrRef}
              type={CONTRACT_TYPE.FREE_TRIAL}
              member={_.get(props, 'member.id', null)}
              showCheckbox={isFreeTrial}
              consentRequired={consentRequired}
              onConsentSigned={() => setConsentRequired(false)}
            />
          </Col>
        </Row>
      </Modal>
      <Swiper
        loop stuckAtBoundary={false}
        className='PackAgesShow'
        indicator={(total, current) => (
          <div className="customIndicator">
            {`${current + 1} / ${total}`}
          </div>
        )}
      >
        {packageItems}
      </Swiper>
    </div>
  );
}
