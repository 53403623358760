import React, { useState} from 'react';
import { Row, Col, Input, Button , Typography, Form, message } from 'antd';
import { CloseCircleOutlined, CloseOutlined, PercentageOutlined } from '@ant-design/icons';
import { URL_SALE } from '../../../constants/urls';
import { POST } from '../../../frameworks/HttpClient';
import { useTranslation } from 'react-i18next';


const PromoCodeSearch = React.forwardRef((props, ref)  => {
  const { cart } = props;
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [ voucherList, setVoucherList ] = React.useState([])
  const [ errorMessages, setErrorMessages ] = React.useState(null);
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);

  React.useImperativeHandle(ref, () => ({
    cancelCode : () => setVoucherList([]),
  }));

  const validateDiscount = async() => {
    let data = await form.validateFields();
    data['cart'] = cart
    data['type'] = 'KIOSK_VOUCHER'
    setErrorMessages(null);
    if(!voucherList.find((e) => e.coupon == data.coupon)){
      try {
        const response = await POST(URL_SALE.DISCOUNT_VALIDATE, data);
        setVoucherList([...voucherList, response.data])
        
      } catch (error) {
        setErrorMessages(error.errorMessages);
        return null;
      }
    } else {
      setErrorMessages("You have already used this code.")
    }
  }

  React.useEffect(() => {
    if(voucherList)
      props.addDiscountList(voucherList)
  },[voucherList])

  return (

      <Form form={form}>
      <Row style={{ textAlign: 'center', width: '100%' }}>
        <Col span={2}>
          <PercentageOutlined
            style={{
              fontSize: '52px',
              color: '#BF1E2D'
            }}
          />
        </Col>
        <Col span={22} style={{ marginLeft : 0}} >
          <Row style= {{ marginLeft : '10'}}>
            <Form.Item name={"kiosk_voucher"} style={{width: 'calc(100% - 115px)', justifyContent: 'flex-end', alignItems: 'center' ,boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <Input
                type="text"
                placeholder="Voucher"
                // allowClear
                allowClear={{ clearIcon: < CloseOutlined  style={{fontSize:'20px' , color:'black' , paddingRight:'10px'}}/> }}
                style={{ height: '56px', fontSize: '20px', borderRadius: '6px 0 0 6px' }}
                className={`ant-input`}
              />
            </Form.Item>
            <Button
              type="primary , submit"
              htmlType="submit"
              style={{ width: '115px' , height:'56px' , fontSize:'20px' , borderRadius:'0 6px 6px 0', }}
              onClick={() => validateDiscount()}
            >
              Apply
            </Button>
          </Row>
        </Col>
        {errorMessages && <Text style={{ fontSize: '20px', color: '#BF1E2D' }}> {t("This_discount_code_is_invalid")} </Text>}
        </Row>
      {/* <Row span={2} offset={4} gutter={[0,50]}>
              <Text style={{color:'#BF1E2D', fontSize:'20px'}}>This discount code is invalid.</Text>
      </Row> */}
    </Form>
  );
});

export default PromoCodeSearch;